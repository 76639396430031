<router-outlet></router-outlet>

<p-toast [key]="appUpdateToastKey" [preventOpenDuplicates]="true">
  <ng-template #message>
    <span>{{ 'APP_UPDATE_TOAST.MESSAGE' | translate }}</span>

    <div class="flex gap-3">
      <p-button
        [autofocus]="true"
        [label]="'APP_UPDATE_TOAST.UPDATE_NOW_BUTTON' | translate"
        (onClick)="activateAppUpdate()"
      >
      </p-button>

      <p-button
        [label]="'APP_UPDATE_TOAST.UPDATE_LATER_BUTTON' | translate"
        severity="secondary"
        [text]="true"
        (onClick)="messageService.clear(appUpdateToastKey)"
      >
      </p-button>
    </div>
  </ng-template>
</p-toast>
